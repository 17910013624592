import React from 'react';

const BaseKit = ({ baseKitArray, extrasArray }) => {
  const renderKitElements = () => {
    return (
      <div className='base-kit-box'>
        {baseKitArray.map((item, index) => {
          return (
            <div key={'row' + index} className='back-row'>
              <h4 className='back-subtitle row-title'>{Object.keys(item)[0]}</h4>
              <p className='displayed-metadata'>{Object.values(item)[0]}</p>
            </div>
          );
        })}
      </div>)
  };
  const renderExtraElements = () => {
    return (
      <div className='base-kit-box'>
        {extrasArray.map((item, index) => {
          return (
            <div key={'row' + index} className='back-row'>
              <h4 className='back-subtitle row-title'>{Object.keys(item)[0]}</h4>
              <p className='displayed-metadata'>{Object.values(item)[0]}</p>
            </div>
          );
        })}
      </div>)
  };
  return (
    <div className='back-content-holder align-left-container'>
      <h3 className='back-title mb-title'>Base Kit</h3>
      {renderKitElements()}

      {extrasArray.length > 0 && (
        <>
          <hr className='mid-line'></hr>

          <h3 className='back-title mb-title'>Extras</h3>
          {renderExtraElements()}
        </>
      )}
    </div>
  );
};

export default BaseKit;
