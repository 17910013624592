import './App.css';
import {
  HashRouter,
  Routes, // instead of "Switch"
  Route,
} from 'react-router-dom';
import routes from './routes/Routes';

function App() {
  return (
    <div className="App">
      <HashRouter basename="/">
        <Routes>
          {routes.map(({ path, component, exact }) => (
            <Route key={path} path={path} element={component} exact={exact} />
          ))}
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
