import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import DataRenderer from './DataRenderer.jsx';
import config from '../config/config';

const CardRender = () => {
    const [loading, setLoading] = useState(true);
    const [metadata, setMetadata] = useState({});
    const location = useLocation();

    useEffect(() => {
        const tokenId = location.search.slice(1);
        fetch(`${config.METADATA_BASE_URL}/${tokenId}.json`)
            .then((response) => response.json()
            )
            .then((data) => {
                setMetadata(data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return (
        <>
            <ClipLoader
                size={150}
                color='rgb(252, 108, 3)'
                loading={loading}
                css={{
                    position: 'absolute',
                    border: '5px solid rgb(252, 108, 3)',
                    borderBottomColor: 'transparent',
                    margin: 'auto',
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                }}
                speedMultiplier={0.5}
            />
            <div style={{width: '100%', height: '100%', overflow: 'hidden'}}>
                {metadata && !loading && <DataRenderer metadata={metadata}/>}
            </div>
        </>
    );
};

export default CardRender;
