import React from 'react'

const Attributes = ({ metadata }) => {
    return (
        <div className='back-content-holder align-left-container'>
            <h3 className='back-title mb-title'>Attributes</h3>
            
                <div className='back-row'>
                    <h4 className='back-subtitle row-title'>Voxo #</h4>
                    <p className='displayed-metadata'>{metadata.token_id}</p>
                </div>
                <div className='back-row'>
                    <h4 className='back-subtitle row-title' >Archetype</h4>
                    <p className='displayed-metadata'>{metadata.archetype}</p>
                </div>
                <div className='back-row'>
                    <h4 className='back-subtitle row-title' >Species</h4>
                    <p className='displayed-metadata'>{metadata.species}</p>
                </div>
                <div className='back-row'>
                    <h4 className='back-subtitle row-title'>Faction</h4>
                    <p className='displayed-metadata'>{metadata.faction}</p>
                </div>
                <div className='back-row'>
                    <h4 className='back-subtitle row-title'>Rarity type</h4>
                    <p  className='displayed-metadata'>{metadata.rarity}</p>
                </div>
                <div className='back-row'>
                    <h4 className='back-subtitle row-title'>Rarity Rank</h4>
                    <p className='displayed-metadata'>{metadata.rarity_rank.toLocaleString()}</p>
                </div>
                <div className='back-row'>
                    <h4 className='back-subtitle row-title'>Rarity Score</h4>
                    <p className='displayed-metadata'>{metadata.rarity_score.toLocaleString()}</p>
                </div>
           
        </div>
    )
}

export default Attributes