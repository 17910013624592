import React from 'react'

const Boosts = ({ boostsArray }) => {
    const renderBoostsElements = () => {
        return (
            boostsArray.map((item, index) => {
                // console.log(Object.keys(item)[0]);
                return <div key={'row' + index} className='back-row'>
                    <h4 className='back-subtitle row-title'>{Object.keys(item)[0]}</h4>
                    <p className='displayed-metadata'>+{Object.values(item)[0]}%</p>
                </div>
            })
        )
    }
    return (
        <div className='back-content-holder align-left-container'>
            <h3 className='back-title mb-title'>Boosts</h3>
            {renderBoostsElements()}
        </div>
    )
}

export default Boosts