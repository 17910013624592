import React, { useCallback, useEffect } from 'react';
import close from '../assets/images/white_close.svg';
import Attributes from './back-types/Attributes';
import BaseKit from './back-types/BaseKit';
import Bio from './back-types/Bio';
import Boosts from './back-types/Boosts';
import './TraitCard.css';

const TraitCard = ({ onClick, metadata, baseKit, extras, boosts, type }) => {
    const renderSelectedBackCard = () => {
        switch (type) {
            case 'bio':
                return <Bio archetType={metadata.archetype} moniker={metadata.moniker} description={metadata.description} />
            case 'attributes':
                return <Attributes metadata={metadata} />
            case 'base-kit':
                return <BaseKit baseKitArray={baseKit} extrasArray={extras} />
            case 'boosts':
                return <Boosts boostsArray={boosts} />
            default:
                break;
        }
    }
    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            onClick(event)
        }
    }, []);
    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, []);

return (
    <>
        <div className='explorer-back-background'>
            {metadata.layers.map((item, index) => (
                <img src={item.image || item.image_path} alt="" key={index} className={`explorer-flipped-img ${index === 4 ? 'back-character-layer' : ''}`} style={{ transform: 'rotateY(180deg)' }} />
            ))}
            <img
                className='explorer-close-button'
                src={close}
                alt=''
                onClick={(e) => onClick(e)}
            />
            {type !== '' && <div className='card-overlay' style={{ zIndex: 2 }}>
                {renderSelectedBackCard()}
            </div>
            }
        </div>
    </>
);
};

export default TraitCard;
